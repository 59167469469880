import type { NavigationGuard } from 'vue-router/auto'
import { useAuthContext } from '@/core/composables/useAuthContext'

export const onboardingMiddleware: NavigationGuard = (to, _from, next) => {
  const { currentCompany } = useAuthContext()

  // If the user is on the unavailable page, we don't need to check if he has a company
  if (to.name === 'companies.unavailable') {
    return next()
  }
  // We redirect to the company creation page if the user has no company and the route requires auth
  if (!currentCompany.value && to.meta.requiresAuth && to.name !== 'companies.create') {
    return next({ name: 'companies.create' })
  }

  return next()
}

