<template>
  <div class="h-full w-full relative z-0">
    <!--
    Note, the following DOM elements are not styled with z-index.
    If z-index is not defined, elements are stacked in the order they appear in the DOM.
    The first element is at the very bottom and subsequent elements are added on top.
    -->
    <video v-show="shouldScan" ref="video" class="h-full w-full block object-cover" autoplay muted playsinline></video>

    <canvas v-show="!shouldScan" ref="pauseFrame" class="h-full w-full block object-cover"></canvas>
    <canvas ref="trackingLayer" class="h-full w-full absolute inset-0"></canvas>
    <div class="h-full w-full absolute inset-0">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BarcodeDetector from '@/core/lib/barcode/BarcodeDetector'
// @ts-expect-error - no types are exported for this lib @TODO refactory
import Camera from '../lib/qrcode/camera.js'
// @ts-expect-error - no types are exported for this lib @TODO refactory
import { keepScanning } from '../lib/qrcode/scanner.js'
// @ts-expect-error - no types are exported for this lib @TODO refactory
import { paintBoundingBox } from '../lib/qrcode/util.js'

type CameraType = 'auto' | 'rear' | 'front' | 'off'
interface QrCodeReaderProps {
  camera?: CameraType
  track?: () => void
}
const props = withDefaults(defineProps<QrCodeReaderProps>(), {
  camera: 'auto',
  track: paintBoundingBox,
})
const emit = defineEmits(['decode', 'init'])

const cameraInstance = ref<typeof Camera | null>(null)
const mounted = ref(false)

const shouldStream = computed(() => mounted.value && props.camera !== 'off')
const shouldScan = computed(() => shouldStream.value && cameraInstance.value !== null)
const scanInterval = computed(() => (props.track === undefined ? 500 : 40))

onBeforeMount(() => {
  window.BarcodeDetector = BarcodeDetector
})

const video = ref()
const pauseFrame = ref()
const trackingLayer = ref()

const clearCanvas = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d')
  ;(ctx as CanvasRenderingContext2D).clearRect(0, 0, canvas.width, canvas.height)
}

const onDetect = async (resultPromise: any) => {
  try {
    const { content } = await resultPromise
    if (content !== null) {
      emit('decode', content)
    }
  } catch (_error) {
    // fail silently
  }
}

const onLocate = (detectedCodes: any) => {
  const canvas = trackingLayer.value
  const v = video.value
  if (canvas !== undefined) {
    if (detectedCodes.length > 0 && props.track !== undefined && v !== undefined) {
      // The visually occupied area of the video element.
      // Because the component is responsive and fills the available space,
      // this can be more or less than the actual resolution of the camera.
      const displayWidth = v.offsetWidth
      const displayHeight = v.offsetHeight
      // The actual resolution of the camera.
      // These values are fixed no matter the screen size.
      const resolutionWidth = v.videoWidth
      const resolutionHeight = v.videoHeight
      // Dimensions of the video element as if there would be no
      //   object-fit: cover
      // Thus, the ratio is the same as the cameras resolution but it's
      // scaled down to the size of the visually occupied area.
      const largerRatio = Math.max(displayWidth / resolutionWidth, displayHeight / resolutionHeight)
      const uncutWidth = resolutionWidth * largerRatio
      const uncutHeight = resolutionHeight * largerRatio
      const xScalar = uncutWidth / resolutionWidth
      const yScalar = uncutHeight / resolutionHeight
      const xOffset = (displayWidth - uncutWidth) / 2
      const yOffset = (displayHeight - uncutHeight) / 2
      const scale = ({ x, y }: any) => {
        return {
          x: Math.floor(x * xScalar),
          y: Math.floor(y * yScalar),
        }
      }
      const translate = ({ x, y }: any) => {
        return {
          x: Math.floor(x + xOffset),
          y: Math.floor(y + yOffset),
        }
      }
      const adjustedCodes = detectedCodes.map((detectedCode: any) => {
        const { boundingBox, cornerPoints } = detectedCode
        const { x, y } = translate(
          scale({
            x: boundingBox.x,
            y: boundingBox.y,
          }),
        )
        const { x: width, y: height } = scale({
          x: boundingBox.width,
          y: boundingBox.height,
        })
        return {
          ...detectedCode,
          cornerPoints: cornerPoints.map((point: any) => translate(scale(point))),
          boundingBox: DOMRectReadOnly.fromRect({ x, y, width, height }),
        }
      })
      canvas.width = v.offsetWidth
      canvas.height = v.offsetHeight
      const ctx = canvas.getContext('2d')
      if (props.track !== undefined) {
        props.track(adjustedCodes, ctx)
      }
    } else {
      clearCanvas(canvas)
    }
  }
}

const startScanning = () => {
  const detectHandler = (result: any) => {
    onDetect(Promise.resolve(result))
  }
  keepScanning(video.value, {
    detectHandler,
    locateHandler: onLocate,
    minDelay: scanInterval.value,
  })
}

watch(shouldStream, (value) => {
  if (!value) {
    const canvas = pauseFrame.value
    const ctx = canvas.getContext('2d')
    const v = video.value
    canvas.width = v.videoWidth
    canvas.height = v.videoHeight
    ctx.drawImage(video, 0, 0, v.videoWidth, v.videoHeight)
  }
})

watch(shouldScan, (value) => {
  if (value) {
    clearCanvas(pauseFrame.value)
    clearCanvas(trackingLayer.value)
    startScanning()
  }
})

const beforeResetCamera = () => {
  if (cameraInstance.value !== null) {
    cameraInstance.value.stop()
    cameraInstance.value = null
  }
}

const init = () => {
  const promise = (async () => {
    beforeResetCamera()
    if (props.camera === 'off') {
      cameraInstance.value = null
      return {
        capabilities: {},
      }
    } else {
      cameraInstance.value = await Camera(video.value, {
        camera: props.camera,
        torch: false,
      })
      const capabilities = cameraInstance.value.getCapabilities()
      // if the component is destroyed before `cameraInstance` resolves a
      // `beforeDestroy` hook has no chance to clear the remaining camera
      // stream.
      if (!mounted.value) {
        cameraInstance.value.stop()
      }
      return {
        capabilities,
      }
    }
  })()

  emit('init', promise)
}

onMounted(() => {
  init()
  mounted.value = true
})

onBeforeUnmount(() => {
  beforeResetCamera()
  mounted.value = false
})
</script>
