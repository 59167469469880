<script lang="ts" setup>
import { Modal } from '@delupay/web-core/components'

const props = defineProps<{
  src?: string
}>()
const emit = defineEmits(['close'])
const showViewer = ref(true)

const playerAvailable = computed(() => props.src && props.src !== '')

watch(showViewer, () => {
  if (!showViewer.value) emit('close')
})

whenever(playerAvailable, () => (showViewer.value = true))
</script>

<template>
  <Modal v-if="playerAvailable" v-model="showViewer" no-padding class="z-50">
    <div class="h-full w-full flex items-center justify-center">
      <img :src="props.src" class="object-contain max-w-full max-h-full" />
    </div>
  </Modal>
</template>
