import { createI18n } from 'vue-i18n'

import messages from '@intlify/unplugin-vue-i18n/messages'

const i18n = createI18n({
  locale: window.navigator.language.split('-')[0],
  fallbackLocale: 'fr',
  messages,
})

export const SUPPORT_LOCALES = ['fr', 'en'] as const
export const localeKeys = {
  fr: 'fr-FR',
  en: 'en-US',
} as Record<SupportLocales, string>
export type SupportLocales = (typeof SUPPORT_LOCALES)[number]

export default i18n
