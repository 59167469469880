import { useGtm } from '@gtm-support/vue-gtm'
import { usePosthog } from '@/core/modules/posthog'

type TrackEventOptions = {
  event: string
  category: string
  action: string
  value?: unknown
}

export const useTracking = () => {
  const postHog = usePosthog()
  const gtm = useGtm()

  const trackEvent = (options: TrackEventOptions) => {
    gtm?.trackEvent(options)
    postHog?.capture(options.event, {
      category: options.category,
      action: options.action,
      value: options,
    })
  }

  const trackView = (page: string, path: string) => {
    gtm?.trackView(page, path)
    postHog?.capture('$pageview', {
      page,
      path,
    })
  }

  return {
    trackEvent,
    trackView,
  }
}
