import type { ImportantNotificationKey } from '@/merchant/api/systemNotifications'
import type { AppColor } from '@/core/utils/colors'

export const importantNotificationColors: { [key in ImportantNotificationKey]: AppColor } = {
  debit_issued: 'warning',
  overdraft_detected: 'warning',
  overdraft_reminder: 'warning',
  debit_rejected: 'danger',
  overdraft_recovered: 'green-600',
}
