import { useRegistration } from '@/merchant/hooks/registration'
import { useAuthStore } from '@/merchant/stores/auth'

import type { NavigationGuard } from 'vue-router/auto'

export const authMiddleware: NavigationGuard = async (to, from, next) => {
  const auth = useAuthStore()
  const { loadUserInfos } = useRegistration()
  const partnerToken = (to.query.partner_token as string) || (to.query.token as string)

  // Special case for registration without partner token
  if (to.name === 'registration.create' && !to.query.partner_token) {
    return next()
  }

  // @NOTE: This is dogshit code... we have to refactor this ASAP to
  //        have only one auth method.

  // 1. token | partner_token valid => connexion
  // 2. session cookie "_delupay_session" valid => connexion
  // 3. error => logout
  if (auth.token || partnerToken) {
    try {
      await loadUserInfos(partnerToken)
    } catch (_err) {
      auth.logout()
    }
  } else if ((!to.meta.onlyPublic || to.meta.requiresAuth) && !auth.loggedIn) {
    try {
      await loadUserInfos()
    } catch (_err) {
      auth.logout()
    }
  }

  // @KEEP: This is the original code
  // if ((!to.meta.onlyPublic || to.meta.requiresAuth) && !auth.loggedIn) {
  //   try {
  //     await loadUserInfos(partnerToken)
  //   } catch (err) {
  //     authComposable.logout()
  //     auth.logout()
  //   }
  // }

  // Already logged in so redirect to dashboard
  if (to.meta.onlyPublic && auth.loggedIn) {
    return next({ name: 'merchant.dashboard' })
  }

  // Special case for registration w/ partner token
  if (from.name === 'registration.create' && from.query.partner_token) {
    auth.logout()
    return next()
  }

  // Special case for password reset
  if (to.name === 'registration.password.reset') {
    auth.logout()
  }

  if (to.meta.requiresAuth && !auth.loggedIn) {
    return next({ name: 'login' })
  }

  if (to.meta.onlyPublic && auth.loggedIn) {
    return next({ name: 'merchant.dashboard' })
  }

  // @NOTE: This is Wawan decision to comment this out because it's not working as is.
  //        I'm not sure what it's supposed to do, but it's not working.
  // Handle route retention
  // if (retainer.route && auth.loggedIn) {
  //   const opt = {
  //     name: retainer.route,
  //     query: retainer.query ? JSON.parse(retainer.query) : undefined,
  //   }
  //   retainer.clear()
  //   return next(opt)
  // }

  // @NOTE: This is Wawan decision to comment this out because it's not working as is.
  //        I'm not sure what it's supposed to do, but it's not working.
  // Tracking logic
  // if (trackingDataInUrl(from.query) && !trackingDataInUrl(to.query)) {
  //   to.query = { ...to.query, ...from.query }
  // }
  // Handle auth-only routes with retention
  // if (to.meta.requiresAuth) {
  //   let redirect = 'public.root'
  //   if (!auth.loggedIn && to.meta.retainPath) {
  //     retainer.route = to.name as string
  //     retainer.query = JSON.stringify(to.query)
  //     redirect = 'login'
  //   }
  //   const opt = auth.loggedIn ? null : { name: (to?.meta?.noAuthRoute as string) || redirect, query }
  //   return next(opt as RouteLocation)
  // }

  // Default case
  next()
}
