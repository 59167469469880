<script setup lang="ts">
import { Modal } from '@delupay/web-core/components'

const props = defineProps<{
  src?: string
  autoplay?: boolean
}>()
const emit = defineEmits(['close'])
const showPlayer = ref(false)

const playerAvailable = computed(() => props.src && props.src !== '')

watch(showPlayer, () => {
  if (!showPlayer.value) emit('close')
})

whenever(playerAvailable, () => (showPlayer.value = true))
</script>

<template>
  <Modal v-if="showPlayer" v-model="showPlayer" no-padding>
    <video class="w-full" :src="src" controls :autoplay="autoplay" />
  </Modal>
</template>

<style lang="scss">
#d-player {
  &.video-js {
    max-width: 100%;
    max-height: calc(100vh - 8rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vjs-tech {
    object-fit: contain;
  }
  .vjs-big-play-button {
    position: relative;
  }
}
</style>
