import posthog from 'posthog-js'
import { env } from '~/env'

import type { App } from 'vue'

const isDev = import.meta.env.DEV

const posthogInstance = posthog.init(env.VITE_POSTHOG_API_KEY, {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'identified_only', // Product analysis
  capture_pageview: false,
  debug: isDev,
})

export const usePosthog = () => {
  return posthogInstance
}

export const install = (app: App): void => {
  app.config.globalProperties.$posthog = posthogInstance
}
