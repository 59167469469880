export const privacyUrl = 'https://www.delupay.com/donnees-personnelles/'
export const legalUrl = 'https://www.delupay.com/mentions-legales/'
export const cguUrl = 'https://www.delupay.com/cgus-pro/'
export const cookiesUrl = 'https://www.delupay.com/cookies/'
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.app.delupay'
export const appleStoreUrl = 'https://apps.apple.com/us/app/delupay/id6444056310'
export const linkedInUrl = 'https://linkedin.com/company/delupay'
export const twitterUrl = 'https://twitter.com/delupay'

export const cguPdf =
  'https://www.delupay.com/legal/adhesion-au-systeme-de-paiement-dematerialise-et-services-associes.pdf'
export const contractPdf =
  'https://www.delupay.com/legal/contrat-cadre-de-services-de-paiement-clientele-de-professionnels-et-entreprises.pdf'

export const onlineCheckoutUrls = {
  'API Direct Connect': {
    faq: 'https://delupay.notion.site/API-Direct-connect-f04dbf7ee4b040cc82456ddf32328dc0',
  },
  Prestashop: {
    faq: 'https://support.delupay.com/hc/fr/articles/9584089368221-Installer-le-plug-in-DeluPay-sur-Prestashop',
    addon: 'https://addons.prestashop.com/en/payment-card-wallet/95052-1-click-payment-by-delupay.html',
  },
  WooCommerce: {
    faq: 'https://support.delupay.com/hc/fr/articles/9768490635549',
    addon: 'https://www.delupay.com/app/woocommerce_delupay.zip',
  },
  Magento: {
    faq: 'https://support.delupay.com/hc/fr/articles/9588323782173-Installer-le-plugin-DeluPay-sur-mon-site-e-commerce',
    addon: 'https://www.delupay.com/app/magento_delupay.zip',
  },
  Drupal: {
    faq: 'https://support.delupay.com/hc/fr/articles/9588323782173-Installer-le-plugin-DeluPay-sur-mon-site-e-commerce',
    addon: 'https://www.delupay.com/app/drupal_delupay.zip',
  },
}
