export const routes = [
  {
    path: '/',
    name: 'public.root',
    component: () => import('/builds/delupay/web/delupay-merchant-web/src/core/pages/index.vue'),
    /* no children */
    meta: {
      "onlyPublic": true,
      "layout": "public"
    }
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/builds/delupay/web/delupay-merchant-web/src/core/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "not-found"
    }
  },
  {
    path: '/companies',
    /* internal name: '/companies' */
    /* no component */
    children: [
      {
        path: 'create',
        name: 'companies.create',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/companies/create.vue'),
        /* no children */
        meta: {
          "gtm": "merchant_onboarding_company",
          "requiresAuth": true,
          "layout": "simple",
          "hidePrev": true
        }
      },
      {
        path: 'unavailable',
        name: 'companies.unavailable',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/companies/unavailable.vue'),
        /* no children */
        meta: {
          "requiresAuth": true,
          "layout": "simple"
        }
      }
    ],
  },
  {
    path: '/dashboard',
    name: 'merchant.dashboard',
    component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/dashboard.vue'),
    /* no children */
    meta: {
      "requiresAuth": true,
      "layout": "default",
      "gtm": "merchant_home_page"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/login.vue'),
    /* no children */
    meta: {
      "onlyPublic": true,
      "layout": "public"
    }
  },
  {
    path: '/me',
    /* internal name: '/me' */
    /* no component */
    children: [
      {
        path: 'account',
        name: 'me.account',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/me/account.vue'),
        /* no children */
        meta: {
          "requiresAuth": true,
          "layout": "default"
        }
      }
    ],
  },
  {
    path: '/orders',
    /* internal name: '/orders' */
    /* no component */
    children: [
      {
        path: '',
        name: 'orders.index',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/orders/index.vue'),
        /* no children */
        meta: {
          "requiresFeature": "merchant_one_to_many",
          "requiresAuth": true,
          "layout": "default"
        }
      }
    ],
  },
  {
    path: '/point_of_sale',
    /* internal name: '/point_of_sale' */
    /* no component */
    children: [
      {
        path: 'account_history',
        name: 'point_of_sale.account_histories',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/point_of_sale/account_history.vue'),
        /* no children */
        meta: {
          "requiresAuth": true,
          "layout": "default",
          "retainPath": true,
          "noAuthRoute": "login"
        }
      },
      {
        path: 'documents',
        name: 'point_of_sale.documents',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/point_of_sale/documents.vue'),
        /* no children */
        meta: {
          "requiresAuth": true,
          "layout": "default",
          "retainPath": true,
          "noAuthRoute": "login"
        }
      }
    ],
  },
  {
    path: '/registration',
    /* internal name: '/registration' */
    /* no component */
    children: [
      {
        path: 'confirmation',
        /* internal name: '/registration/confirmation' */
        /* no component */
        children: [
          {
            path: '',
            name: 'registration.confirmation.index',
            component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/registration/confirmation/index.vue'),
            /* no children */
            meta: {
              "layout": "simple",
              "gtm": "merchant_onboarding_email_code"
            }
          }
        ],
      },
      {
        path: 'create',
        name: 'registration.create',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/registration/create.vue'),
        /* no children */
        meta: {
          "layout": "simple",
          "gtm": "merchant_onboarding_form"
        }
      },
      {
        path: 'password',
        /* internal name: '/registration/password' */
        /* no component */
        children: [
          {
            path: ':token',
            name: 'registration.password.reset',
            component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/registration/password/[token].vue'),
            /* no children */
            meta: {
              "onlyPublic": true,
              "layout": "simple"
            }
          },
          {
            path: 'forget',
            name: 'registration.password.forget',
            component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/registration/password/forget.vue'),
            /* no children */
            meta: {
              "onlyPublic": true,
              "layout": "simple"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/settings',
    name: '/settings',
    component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings.vue'),
    children: [
      {
        path: 'application',
        /* internal name: '/settings/application' */
        /* no component */
        children: [
          {
            path: '',
            name: 'settings.application.index',
            component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/application/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "layout": "default",
              "nav": false,
              "retainPath": true
            }
          }
        ],
      },
      {
        path: 'notifications',
        /* internal name: '/settings/notifications' */
        /* no component */
        children: [
          {
            path: '',
            name: 'settings.notifications.index',
            component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/notifications/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "layout": "default",
              "nav": false
            }
          }
        ],
      },
      {
        path: 'point_of_sale',
        /* internal name: '/settings/point_of_sale' */
        /* no component */
        children: [
          {
            path: 'catalog',
            /* internal name: '/settings/point_of_sale/catalog' */
            /* no component */
            children: [
              {
                path: '',
                name: 'settings.point_of_sale.catalog',
                component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/catalog/index.vue'),
                /* no children */
                meta: {
                  "requiresFeature": "merchant_one_to_many",
                  "requiresAuth": true,
                  "layout": "default",
                  "nav": true
                }
              },
              {
                path: 'list',
                /* internal name: '/settings/point_of_sale/catalog/list' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'settings.point_of_sale.catalog.list.update',
                    component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/catalog/list/[id].vue'),
                    /* no children */
                    meta: {
                      "requiresFeature": "merchant_one_to_many",
                      "requiresAuth": true,
                      "layout": "default",
                      "nav": true
                    }
                  },
                  {
                    path: 'create',
                    name: 'settings.point_of_sale.catalog.list.create',
                    component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/catalog/list/create.vue'),
                    /* no children */
                    meta: {
                      "requiresFeature": "merchant_one_to_many",
                      "requiresAuth": true,
                      "layout": "default",
                      "nav": true
                    }
                  }
                ],
              },
              {
                path: 'product',
                /* internal name: '/settings/point_of_sale/catalog/product' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'settings.point_of_sale.catalog.product.update',
                    component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/catalog/product/[id].vue'),
                    /* no children */
                    meta: {
                      "requiresFeature": "merchant_one_to_many",
                      "requiresAuth": true,
                      "layout": "default",
                      "nav": true
                    }
                  },
                  {
                    path: 'create',
                    name: 'settings.point_of_sale.catalog.product.create',
                    component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/catalog/product/create.vue'),
                    /* no children */
                    meta: {
                      "requiresFeature": "merchant_one_to_many",
                      "requiresAuth": true,
                      "layout": "default",
                      "nav": true
                    }
                  }
                ],
              }
            ],
          },
          {
            path: 'checkouts',
            /* internal name: '/settings/point_of_sale/checkouts' */
            /* no component */
            children: [
              {
                path: '',
                name: 'settings.point_of_sale.checkouts',
                component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/checkouts/index.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "layout": "default",
                  "nav": true
                }
              }
            ],
          },
          {
            path: 'company',
            /* internal name: '/settings/point_of_sale/company' */
            /* no component */
            children: [
              {
                path: '',
                name: 'settings.point_of_sale.company',
                component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/company/index.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "layout": "default",
                  "nav": true,
                  "retainPath": true
                }
              }
            ],
          },
          {
            path: 'details',
            name: 'settings.point_of_sale.details',
            component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/details.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "layout": "default",
              "nav": true
            }
          },
          {
            path: 'payment_methods',
            /* internal name: '/settings/point_of_sale/payment_methods' */
            /* no component */
            children: [
              {
                path: '',
                name: 'settings.point_of_sale.payment_methods',
                component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/point_of_sale/payment_methods/index.vue'),
                /* no children */
                meta: {
                  "requiresFeature": "merchant_can_change_payment_types",
                  "requiresAuth": true,
                  "layout": "default",
                  "nav": true
                }
              }
            ],
          }
        ],
      },
      {
        path: 'security',
        /* internal name: '/settings/security' */
        /* no component */
        children: [
          {
            path: 'authentication',
            /* internal name: '/settings/security/authentication' */
            /* no component */
            children: [
              {
                path: 'configuration',
                name: 'settings.authentication.configuration',
                component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/security/authentication/configuration.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "layout": "default"
                }
              }
            ],
          }
        ],
      },
      {
        path: 'sponsorships',
        /* internal name: '/settings/sponsorships' */
        /* no component */
        children: [
          {
            path: '',
            name: 'settings.sponsorships.index',
            component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/settings/sponsorships/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "layout": "default",
              "nav": false
            }
          }
        ],
      }
    ],
  },
  {
    path: '/transactions',
    /* internal name: '/transactions' */
    /* no component */
    children: [
      {
        path: '',
        name: 'transactions.index',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/transactions/index.vue'),
        /* no children */
        meta: {
          "requiresAuth": true,
          "layout": "default",
          "showNewTransactionCta": true
        }
      },
      {
        path: ':reference',
        name: 'transactions.show',
        component: () => import('/builds/delupay/web/delupay-merchant-web/src/merchant/pages/transactions/[reference].vue'),
        /* no children */
        meta: {
          "requiresAuth": true,
          "layout": "default"
        }
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

