import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

import { type _Company, type _Registration } from '@/types/TrueTypes'
import type { Registration } from '../api/registration'
import { mapDataToSnakeCase } from '@/core/api/client'

export interface _User extends _Registration {}

export const _useUserStore = defineStore('_user', () => {
  const user = ref<_User>()

  const activeCompanyId = useSessionStorage<number>(`session.activeCompanyId`, 0)
  const currentCompany = computed(() => {
    if (!activeCompanyId) return

    if (!user.value || !user.value.companies) return

    return user.value.companies.find((company) => company.id === activeCompanyId.value) as unknown as _Company
  })
  const setActiveCompanyId = (id: number) => {
    activeCompanyId.value = id
  }

  const activePointOfSaleId = useSessionStorage<number>(`session.activePointOfSaleId`, 0)
  const currentPointOfSale = computed(() => {
    if (!activePointOfSaleId) return

    if (!user.value || !user.value.point_of_sales) return

    const pos = user.value.point_of_sales.find((pos) => pos.id === activePointOfSaleId.value)

    if (!pos) throw new Error('User store error: no pos is set...')

    return pos
  })
  const setActivePointOfSaleId = (id: number) => {
    activePointOfSaleId.value = id
  }

  const activeCheckoutNumber = useSessionStorage<string | null>('session.activeCheckoutNumber', null)
  const setActiveCheckoutNumber = (checkoutNumber: string) => {
    activeCheckoutNumber.value = checkoutNumber
  }

  const isContractSigned = computed(() => {
    if (!currentPointOfSale.value) return false

    return currentPointOfSale.value.completed_sections?.signature || false
  })

  const isMissingMerchantInformation = computed(() => {
    if (!currentPointOfSale.value) return false

    return currentPointOfSale.value.missing_merchant_information || false
  })

  return {
    user: computed(() => user.value),

    setUser: (_user: Registration) => {
      if (!_user) return

      user.value = mapDataToSnakeCase(_user) as unknown as _Registration

      const companies = user.value.companies
      const userCompanyExists = companies.find((c) => c.id === activeCompanyId.value)

      if (companies.length > 0 && (activeCompanyId.value < 1 || !userCompanyExists)) {
        activeCompanyId.value = user.value.companies[0].id
      }

      const pos = user.value.point_of_sales
      const userPosExists = pos.find((p) => p.id === activePointOfSaleId.value)

      if (pos.length > 0 && (activePointOfSaleId.value < 1 || !userPosExists)) {
        activePointOfSaleId.value = user.value.point_of_sales[0].id
      }
    },

    currentCompanyId: computed(() => Number(activeCompanyId.value)),
    currentCompany,
    setActiveCompanyId,

    currentPointOfSaleId: computed(() => Number(activePointOfSaleId.value)),
    currentPointOfSale,
    setActivePointOfSaleId,

    currentCheckoutNumber: computed(() => activeCheckoutNumber.value),
    setActiveCheckoutNumber,

    isContractSigned,
    isMissingMerchantInformation,
    isProductionModeEnabled: computed(
      () => user.value?.point_of_sales.find((p) => p.id === activePointOfSaleId.value)?.production_mode_enabled,
    ),

    reset: () => {
      user.value = undefined
      activeCompanyId.value = null
      activePointOfSaleId.value = null
      activeCheckoutNumber.value = null
    },
  }
})
