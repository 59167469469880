import { useStorage } from '@vueuse/core'

export const useTestMode = () => {
  const storedTestMode = useStorage<boolean>('settings.testMode', true)

  const toggleTestMode = () => {
    setTestMode(!storedTestMode.value)
  }

  const setTestMode = (value: boolean) => {
    storedTestMode.value = value
  }

  return {
    testMode: storedTestMode,
    toggleTestMode,
    setTestMode,
  }
}
